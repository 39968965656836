import { request } from "../utils/axios"

export default {
	// 营销 ===> 抽奖活动
	getActivityMaintList(params = {}) {
		return request('get', `/raffle/get_activity_maint_list`, params);
	},

	initActivity(params = {}) {
		return request('get', `/raffle/init_activity`, params);
	},

	saveActivity(params = {}) {
		return request('post', `/raffle/save_activity`, params);
	},

	deleteActivity(params = {}) {
		return request('get', `/raffle/delete_activity`, params);
	},

	getPrizeList(params = {}) {
		return request('get', `/raffle/get_prize_list`, params);
	},

	savePrize(params = {}) {
		return request('post', `/raffle/save_prize`, params);
	},

	deletePrize(params = {}) {
		return request('get', `/raffle/delete_prize`, params);
	},

	// 营销 ===> 抽奖记录
	getWinDataList(params = {}) {
		return request('get', `/raffle/get_win_data_list`, params);
	},

	verifyWinData(params = {}) {
		return request('get', `/raffle/verify_win_data`, params);
	},

	// 营销 ===> 活动设置
	initSetting(params = {}) {
		return request('get', `/raffle/init_setting`, params);
	},

	saveSetting(params = {}) {
		return request('post', `/raffle/save_setting`, params);
	},


}
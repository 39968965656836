<template>
  <div>
    <el-dialog
      title="核销"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" :inline="false" size="small">
          <el-form-item label="门店核销员" required>
            <el-select
              v-model="submitVerify.verifier_guid"
              placeholder="选择核销员"
              filterable
              @change="onChangeVerifyMan"
            >
              <el-option
                v-for="item in verifyManList"
                :key="item.VerifierGuid"
                :label="(item.Role ? '(管理员)' : '(员工)') + item.VerifierName"
                :value="item.VerifierGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="核销码" required>
            <el-input
              v-model="submitVerify.verify_code"
              placeholder="请输入核销码"
              clearable
              @change=""
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="verifyDisabled"
          @click="verifyWinData"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      verifyManList: [],
      submitVerify: {
        verifier_guid: "",
        verifier_name: "",
        verify_code: "",
      },
      showDialog: false,
      verifyDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 获取核销人员列表
    async getVerifierSelector() {
      try {
        let { data } = await Shop.getVerifierSelector();
        this.verifyManList = data;
        // this.submitVerify.order_id = this.orderId;
      } catch (err) {
        console.log(err);
      }
    },

    // 核销
    async verifyWinData() {
      let that = this;
      let { submitVerify } = this;
      if (!submitVerify.verifier_guid) {
        return this.$message.error("请选择核销人员");
      } else if (!submitVerify.verify_code) {
        return this.$message.error("请输入核销码");
      }

      this.$confirm("确认核销此奖品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.verifyDisabled = true;

        try {
          let { errcode } = await Raffle.verifyWinData(submitVerify);
          if (errcode == 0) {
            that.$message.success("核销成功");
            that.$emit("success");
            that.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        } finally {
          that.verifyDisabled = false;
        }
      });
    },

    // 核销员改变事件
    onChangeVerifyMan(event) {
      this.submitVerify.verifier_name = this.verifyManList.find(
        (item) => item.VerifierGuid == event
      ).VerifierName;
      // console.log(this.submitVerify);
    },

    // 打开对话框
    onShowDialog(winId) {
      this.submitVerify.win_id = JSON.parse(JSON.stringify(winId));
      this.getVerifierSelector();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>

<template>
  <div class="history">
    <div class="df-row">
      <div class="df-col">
        <el-select
          v-model="submitData.activity_id"
          size="mini"
          placeholder="请选择抽奖活动"
        >
          <el-option
            v-for="item in activityList"
            :key="item.ActivityId"
            :label="item.ActivityName"
            :value="item.ActivityId"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.status"
          size="mini"
          placeholder="请选择状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="" label="头像">
        <template slot-scope="scope">
          <el-image :src="scope.row.MemberHead" fit="fill"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="MemberName" label="昵称"></el-table-column>
      <el-table-column prop="PrizeName" label="中奖奖品"></el-table-column>
      <el-table-column prop="" label="状态">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.Status == 0
                ? 'primary'
                : scope.row.Status == 1
                ? 'success'
                : 'danger'
            "
            >{{
              scope.row.Status == 0
                ? "待核销"
                : scope.row.Status == 1
                ? "已核销"
                : "已过期"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="中奖时间">
        <template slot-scope="scope">
          {{
            scope.row.WinTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.WinTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="过期时间">
        <template slot-scope="scope">
          {{
            scope.row.VerifyExpireDate
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.VerifyExpireDate * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="核销时间">
        <template slot-scope="scope">
          {{
            scope.row.VerifyTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.VerifyTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="VerifierName" label="核销人员"></el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            v-show="scope.row.Status == 0"
            type="primary"
            size="mini"
            @click="onShowVerify(scope.row)"
            >核销</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <VerifyDialog ref="VerifyDialog" @success="getWinDataList"></VerifyDialog>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";
import VerifyDialog from "./components/verify-dialog.vue";

export default {
  components: { VerifyDialog },

  data() {
    return {
      tableMaxHeight: 0,
      totalRecord: 0,
      activityList: [],
      statusList: [
        {
          Name: "全部",
          Value: -1,
        },
        {
          Name: "待核销",
          Value: 0,
        },
        {
          Name: "已核销",
          Value: 1,
        },
        {
          Name: "已过期",
          Value: 2,
        },
      ],
      tableList: [],
      submitData: {
        activity_id: "",
        status: -1,
        page_index: 1,
        page_size: 10,
      },
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.getActivityMaintList();
    this.getWinDataList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取活动列表
    async getActivityMaintList() {
      try {
        let { data } = await Raffle.getActivityMaintList({
          status: -1,
          word: "",
          page_size: 1000,
          page_index: 1,
        });
        this.activityList = [
          { ActivityId: "", ActivityName: "全部" },
          ...data.list,
        ];
        // this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取抽奖记录
    async getWinDataList() {
      this.loading = true;
      try {
        let { data } = await Raffle.getWinDataList(this.submitData);
        this.totalRecord = data.filter.TotalRecord;
        this.tableList = data.list;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getWinDataList();
    },

    // 打开核销对话框
    onShowVerify(event) {
      this.$refs.VerifyDialog.onShowDialog(event.WinId);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getWinDataList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getWinDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.history {
  .df-row {
    margin-block-start: 20px;
  }

  .el-table {
    .el-image {
      width: 60px;
      height: 60px;
    }
  }

  .el-pagination {
    margin-block-start: 20px;
    text-align: right;
  }
}
</style>
